*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.no-padding{
    padding:0px!important
}
.no-margin{
    margin:0px!important
}
.rel_position{
    position: relative;
    height: 110vh;      
}
.login_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);  
    width: 50%;      
}
.border_out{
    border: solid 1px;
}
.login_box_pad{
    padding: 30px;
}
.padding_top_default{
    padding-top: 15px;
}
.margin_top_default{
    margin-top: 15px;
}
.register_bg{
    background-color: blue;
}
.white_bg{
    background-color: white;
}
.text-gray{
    color: rgb(189, 187, 187);
}
.input_style{
    padding: 10px;
}
.loginBtn-pad{
    padding: 4px;
}
.reg_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);  
    width: 40%;      
}
.regBtn-color{
    background-color: green;
}
.ali{
    display: table-cell;
    vertical-align: middle
}
.hom_position{
    display: table;
    width: 100%;
    height: 110vh;
    background-color: lightgray;    
}
.height-100{
    height: 100vh;
}
.transTableHeight{
    height: 80vh;
}
.home_content_border{    
    border-radius: 10px;
    box-shadow: 0px 3px 10px gray;
}
.home_table_box{
    margin-top: 50px;
}
.rt-th{
    font-weight: 700;
}


* {box-sizing: border-box}
body {font-family: "Lato", sans-serif;}

/* Style the tab */
.tab {
  float: left;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
  width: 30%;
  height: 300px;
}


.homContainer {
    margin: 0 auto;
}
/* Style the buttons inside the tab */
.tab button {
  display: block;
  background-color: inherit;
  color: black;
  padding: 22px 16px;
  width: 100%;
  border: none;
  outline: none;
  text-align: left;
  cursor: pointer;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current "tab button" class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  float: left;
  padding: 100px 12px;
  border: 1px solid #ccc;
  width: 70%;
  border-left: none;
  height: 300px;
  align-content: center;
}
.datePicker {
    display: inline-block;
}
.logoutlinks {
    background-color: red !important;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 2px 0 2px 0;
}
.form_box{    
    width: 75%; 
    margin: auto;         
}
.form_responsive_height{
    height: 100vh;
}

.expenseList_box{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%,-50%);  
    width: 75%;      
}
.expenseList_box_pad{
    padding: 15px;
}

/*modal*/
.modal_position{
    position: absolute!important;
    width: 100%;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
}

/* The sidebar menu */
.sidenav {
    height: 100vh; /* Full-height: remove this if you want "auto" height */
    /* width: 160px; 
    position: fixed; 
    z-index: 1;
    top: 0; 
    left: 0; */
    background-color: #111; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  /* Style page content */
  .main {
    /* margin-left: 160px; */
    padding: 0px 10px;
  }
  .chart {
      width:720px;
  }
  .chart_box_pad{
      padding: 30px 90px!important;
  }


  /* media query starts here */

  @media only screen and (max-width: 992px) {
    .login_box{
        width: 75%;
    }
    .chart_box_pad{
        padding: 10px 20px!important;
    }
  }
  @media only screen and (max-width: 600px) {
    .form_box{
        width: 75%!important;
    }
  }
  @media only screen and (max-width: 767.9px) {
    .sidenav{
        height: 40vh!important;        
    }
    .form_responsive_height, .height-100, .report_responsive_height{
        height: 60vh!important;
    }
    .logout_btn_responsive_margin{
        margin: 15px 10px 0px 10px !important;        
    }
    .reg_box{
        width: 75%;        
    }
    .login_box_pad, .expenseList_box_pad{
        padding: 15px;
    }
    .logoutlinks{
        width: 20%!important;
    }
    .form_box{
        position: relative!important;
        top: 20px;
        width: 50%;
        left: 0px;
        margin: auto;
        transform: translate(0px, 0px);
    }
    .expenseList_box{
        position: relative!important;
        top: 20px;
        width: 80%;
        left: 0px;
        margin: auto;
        transform: translate(0px, 0px);
    }
    .list_table_responsive_scroll{
        max-height: 80vh;
        overflow-y: scroll;
    }
    .form_position{
        position: absolute;
        left: 50%;
        top: 70%!important;
        transform: translate(-50%, -50%);
        width: 90%!important;
      }
  }





  .navbar_dropshadow{
      box-shadow: 0px 3px 5px lightgrey;
  }
  .form_drop_shadow{
    box-shadow: 0px 2px 5px lightgrey;
  }
  .list_table_box{
      margin: 15px;
      box-shadow: 0px 2px 5px lightgrey;
  }
  .report_shadow{      
      box-shadow: 0px 2px 5px lightgrey;
  }
  .form_position{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 55%;
  }
  .login_box_shadow{
    box-shadow: 0px 2px 5px lightgrey;
  }
  .login_box_leftradius{
      border-radius: 10px 0px 0px 10px;
  }
  .login_box_rightradius{
    border-radius: 0px 10px 10px 0px;
  }
  .register_box{
    box-shadow: 0px 2px 5px lightgrey;
    border-radius: 10px;
  }